const experience = [
    {
        id: 9,
        startDate: "Nov 2022",
        endDate: "current",
        title: "Festsetzung",
        tasks: [
            "Entwicklung eines Clients zur Bearbeitung von Finanzdaten",
            "Requierenment E. und Coaching nach IREB-Standard",
            "Softwarearchitektur nach iSQB-Standard",
            "Erstellung querschnittlicher Konzepte",
            "Etablierung agiler Softwareentwicklung im Entwicklungsteam (Scrum)",
            "Coaching des Product-Owners bei der agilen Projektführung auf Basis heuristischer Erfahrungen",
            "Coaching der Entwickler",
            "Einführung von automatisierten Oberflächentests",
            "Lösungsfindung für außergewöhnliche Randbedingungen",
            "Schnittstellenentwicklung",
        ],
        tools: "Java 11, IntelliJ, git, Github, Jira, Confluence, Jenkins, Maven, Artifactory, MapStruct, Lombok, JUnit, proprietäres Kommunikationsprotokoll, Scrum",
    },
    {
        id: 9,
        startDate: "Mai 2022",
        endDate: "current",
        title: "Pingo",
        tasks: ["Beratung bei Neuentwicklung von analog aufgebauten Applikationen",
            "Schnittstellenentwicklung",
            "Anforderungsanalyse",
            "Planung",
            "Implementierung",
            "Wartung der 2018 bis 2020 entwickelten Applikationen",
        ],
        tools: "Java 11, IntelliJ, git, Bitbucket, Jira, Confluence, Jenkins, Maven, Nexus, Dagger, JAXB, MapStruct, Lombok, JUnit, proprietäres Kommunikationsprotokoll für XML, XSD, Kanban, Spring-Boot",
    },
    {
        id: 9,
        startDate: "Apr 2022",
        endDate: "Mai 2022",
        title: "Vision Days",
        tasks: ["Entwicklung einer Progressive-Web-App (PWA)",
            "Begleit-App & Webseite für eine 3-tägige Konferenz",
            "Anforderungsanalyse",
            "UX-Design",
            "Implementierung",
            "Wartung & Betrieb"
        ],
        tools: "Angular 13, PWA (iOS, Android, PC), Responsive, Service-Worker, RxJS, Routing, Animations, Push-Benachrichtigungen, REST, Netlify, Github, Visual Studio Code, Docker, Oracle Visual Builder Studio (CI/CD)",
    },
    {
        id: 8,
        startDate: "Apr 2020",
        endDate: "Mai 2022",
        title: "BPA-Euro",
        tasks: ["Entwicklung einer Single-Page-Application für die Betriebsprüfung und Steuerfahndung",
            "Anforderungsanalyse",
            "Design",
            "Implementierung",
            "Entwicklung von Benutzeroberflächen unter Berücksichtigung des Styleguides und Aspekten der Barrierefreiheit",
            "Schnittstellendesign mit Spring Boot & Swagger"
        ],
        tools: "Angular 9-13, Java 11, OpenApi 3, RxJS, NGXS (Redux), Syncfusuion, Cypress, Adobe XD, Webstorm / IntelliJ, Visual Studio Code, git (Bitbucket), Artifactory, Jenkins, npm, Gradle, Jira, Confluence, Spring-Boot, Swagger, REST, SCRUM, Docker",
    },
    {
        id: 4,
        startDate: "Mar 2018",
        endDate: "Apr 2020",
        title: "EBilanz, Elsterlohn2, Ginster-Verzeichnisdienste",
        tasks: ["Development of various clients for processing financial data",
            "Establishment of agile software development within the development team",
            "Administration of the CI/CD pipeline (git, Jenkins, Maven, Nexus)",
            "Design and administration of the cross-site Maven project (50+ custom-developed modules and their dependencies)",
            "Sprint planning",
            "Project and site-wide in-depth knowledge"
        ],
        tools: "Java, git, Jenkins, Maven, Nexus",
    },
    {
        id: 7,
        startDate: "Mai 2019",
        endDate: "Aug 2019",
        title: "Shared Stack",
        tasks: ["Entwicklung einer Library zur Vereinheitlichung der Kommunikations-schicht der verschiedenen Clients",
            "Anforderungsanalyse",
            "Design",
            "Planung",
            "Testgetriebene Entwicklung",
            "Wartung",
        ],
        tools: "Java 8, Eclipse, IntelliJ, git (Bitbucket, Gitlab, Github Enterprise), Jira, Confluence, Jenkins, Maven, Nexus, Dagger, JAXB, MapStruct, Beanfabrics, SWT, JUnit, proprietäres Kommunikationsprotokoll auf Basis von XML, XSD",
    },
    {
        id: 3,
        startDate: "Sep 2017",
        endDate: "Feb 2018",
        title: "Data preparation tools, Bayer",
        description:
            "Development of multiple data crawlers and data processing units for various sources and websites.",
        tasks: ["Integration of data sources",
            "Data prepartation",
            "Mapping to the customer database interface",
            "Development of a user interface which controls the various tools"],
        tools: "Java 8, Spring, Maven, Eclipse, git, Postman, REST, SOAP, Swing",
    },
    {
        id: 2,
        startDate: "Dec 2017",
        title: "CAIRNew Mailbot, Bayer",
        description:
            "A mailbot that reads PDF email attachments, extracts information regarding chemical accidents and rule violations from various pre-written forms and then uploads the data into an incident database.",
        tasks: ["Expanding and maintaining an email bot.",
            "Implementing new readout-processes for various form letters",
            "Mapping to the customer database interface."
        ],
        tools: "Java 8, Spring, Maven, Eclipse, git, Postman, REST, SOAP, Swing"
    },
    {
        id: 1,
        startDate: "Sep 2015",
        title: "Lernfisch",
        description:
            "Learning application for North Rhine-Westphalia's fishing license.",
        tasks: ["Backend based on MySQL and PHP", "Frontend written with HTML, JavaScript and CSS"],
        tools: "HTML, PHP, JavaScript, CSS, MySQL, Eclipse/Aptana, PhpStorm"
    }
];

export default experience;
